<template>
  <div  class="bg-white p-5 w-11/12 mx-auto rounded-3xl box"  v-if="!processing && !successAlert">
    <h1 class="text-4xl mb-2 font-bold">Apply</h1>
    <h2 class="welcome-text  text-xl text-gray-500 font-bold">Become a Bark Badge Wholesaler!</h2>
    <p class="text-red-500 italic">required</p>
    <p class="text-lg">{{feedback}}</p>
    <div class="block w-full text-left mx-auto rounded-3xl " v-if="regStep == 0">
        <div class="mb-4" v-for="(field,idx) in form_fields" :key="idx">
          <label class="block text-gray-500 text-sm font-bold mb-2"  for="username">
            {{field.label}} <span class="text-red-200" ></span>
          </label>
          <input
            class=" shadow appearance-none border rounded w-full py-2 px-3 text-gray-500 leading-tight focus:outline-none focus:shadow-outline"  :type="field.input_type"
            :class="{'border-red-500': reminder}"
            v-model="field.value" :placeholder="field.placeholder"
          />
        </div>
    </div>
    <hr class="w-8/12 mx-auto my-3"/>
    <div class="block w-full text-left mx-auto rounded-3xl"  v-if="regStep == 1">
        <div class="mb-4" v-for="(field,idx) in rep_fields" :key="idx">
          <label class="block text-gray-500 text-sm font-bold mb-2"  for="username">
            {{field.label}}
          </label>
          <input
            class=" shadow appearance-none border rounded w-full py-2 px-3 text-gray-500 leading-tight focus:outline-none focus:shadow-outline"  :type="field.input_type" v-model="field.value" :placeholder="field.placeholder"
          />
        </div>
        <!-- <div class="mb-4" >
          <label class="block text-gray-500 text-sm font-bold mb-2"  for="password">Password</label>
          <input
            class=" shadow appearance-none border rounded w-full py-2 px-3 text-gray-500 leading-tight focus:outline-none focus:shadow-outline" id="password" type="password" v-model="password" placeholder="Password"
          />
        </div> -->
    </div>

    <p>Already have an account? <a href="/login" class="text-theme font-bold">Login</a></p>

    <div class="flex justify-center items-center">
      <button v-if="regStep == 1" class="submitWait bg-black" @click.prevent="moveSlide(0)">
        Back
      </button>
      <button class="submitWait bg-theme" @click.prevent="validate()">
        <span  v-if="regStep == 1">Apply</span>
        <span v-else>Next</span>
      </button>
    </div>
  </div>

  <div class=" bg-white p-12 w-11/12 md:w-8/12 lg:5/12 mx-auto rounded-3xl box" v-else-if="processing">
    <p class="italic">Please Wait...</p>
  </div>
  <div class=" bg-white p-12 w-11/12 md:w-8/12 lg:5/12 mx-auto rounded-3xl box text-center" v-else-if="!processing && successAlert">
    <h1 class="text-3xl lg:text-4xl my-10 font-bold mx-auto text-green-400">Thank You for Applying!</h1>
    <p  class="text-lg lg:text-2xl my-5 text-gray-600">If you are approved, we will send you a follow up email with additional instructions to enter your account!</p>
    <!-- <p  class="text-lg lg:text-3xl text-gray-600" >In the meantime feel free to follow us on social media!</p> -->
  </div>
</template>
<script>
import axios from 'axios'
// import {fb} from '@/_firebase/init'

export default {
  name: "Application",
  components: {},
  data() {
    return {
      processing: false,
      successAlert: false,
      status: '',
      reminder: false,
      regStep: 0,
      feedback: '',
      form_fields: [
        {label: 'Company Name', placeholder:'Acme Co.', value:'', input_type:'text', isRequired:false},
        {label: 'Company Phone Number', placeholder:'+1 (770) 555-5555', value:'', input_type:'text', isRequired:false},
        {label: 'Company Email', placeholder:'my@email.com', value:'', input_type:'text', isRequired:false},
        {label: 'Country', placeholder:'United States', value:'', input_type:'text', isRequired:false},
        {label: 'Representative Name', placeholder:'John Doe', value:'', input_type:'text', isRequired:false},

      ],
      rep_fields: [
        {label: 'What does your company do?', placeholder:'Dog grooming, Dog walking, etc..', value:'', input_type:'text', isRequired:false},
        {label: 'Website', placeholder:'example.com', value:'', input_type:'text', isRequired:false},
        {label: 'Expected monthly customer volume', placeholder:'500-10,000', value:'', input_type:'text', isRequired:false},
        {label: 'Distribution Channels', placeholder:'Point of Sale, Online Store, etc..', value:'', input_type:'text', isRequired:false},
      ],
      social_fields:[
        {label: 'Facebook', placeholder:'facebook.com/', value:'', input_type:'text', isRequired:false},
        {label: 'Twitter', placeholder:'twitter.com or @twitter', value:'', input_type:'text', isRequired:false},
        {label: 'Instagram', placeholder:'instagram.com or @instagram', value:'', input_type:'text', isRequired:false},
        {label: 'LinkedIn', placeholder:'linkedin.com/', value:'', input_type:'text', isRequired:false},
      ],
      password: '',
      bkgd_img: 'https://images.unsplash.com/photo-1504194782011-e78a3bf9d300?ixlib=rb-1.2.1&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=870&q=80',
    };
  },
  methods: {
    moveSlide(x){
      this.regStep = x
    },
    validate(){
      if(this.form_fields[0].value == '' ||
          this.form_fields[1].value == '' ||
          this.form_fields[2].value == '' ||
          this.form_fields[3].value == '' ||
          this.form_fields[4].value == '' ){
        this.feedback = 'Please enter all fields in this section'
        this.reminder = true
      }else{
        if(this.regStep == 1){
          this.sendApplicationForm()
        }else{
            this.regStep = 1
        }
      }
    },
    sendApplicationForm() {
      this.processing = true
      const config ={
        headers: {}
      }
      const payload = {
        businessname: this.form_fields[0].value,
        represenative: {
          fname: this.form_fields[4].value,
          lname: '',
        },
        phone: this.form_fields[1].value,
        email: this.form_fields[2].value,
        addresses: [],
        country: this.form_fields[4].value,
        product_service: this.rep_fields[0].value,
        website: this.rep_fields[1].value,
        customer_volume: this.rep_fields[2].value,
        distribution_channels: this.rep_fields[3].value,
      }
      axios.post('https://petsproject.skylineexecutive.com/wholesale/new_application', payload, config
      ).then((res)=>{
        this.processing = false
        console.log(res.data)
        this.successAlert = true
      }).catch((err)=>{
        console.log(err)
      })
    },
    // register(){
    //     this.feedback = ""
    //   this.processing = true
    //   fb.auth().createUserWithEmailAndPassword(this.userdata.email, this.userdata.password)
    //     .then((userCredential) => {
    //       console.log('triggered')
    //       // Signed in
    //       var user = userCredential.user;
    //       console.log(user)
    //       this.logUserData(user.uid)
    //     })
    //     .catch((error) => {
    //       this.feedback = error.message;
    //       this.processing = false
    //       console.log(error)
    //       // ..
    //     });
    // },
  },
};
</script>
<style scroped>

.box {
  min-width: 400px;
  border: 2px solid #603784;
  box-shadow: 0 25px 50px -12px rgba(255, 255, 255, 0.25) !important;
}
.welcome-text{
  color: #526A92;
}
.submitWait {
  padding: 10px;
  width: 20%;
  border-radius: 10px;
  margin: 0 20%;
  color: #fff;
  transition: 1s;
}
.submitWait:hover{
  opacity: .8;
}
.customeCard {
  width: 50%;
  display: block;
  margin: auto;
}
.waitListBtn {
  display: flex;
  margin: auto;
}
.callnow {
  background: rgba(255, 255, 255, 0.1);
  box-shadow: 0 8px 32px 0 rgba(31, 38, 135, 0.37);
  backdrop-filter: blur(3.5px);
  -webkit-backdrop-filter: blur(3.5px);
  border-radius: 5px;
  border: 1px solid rgba(255, 255, 255, 0.18);
}
.req-quote-group {
  width: 100%;
  height: 100%;
}
.quote-form {
  width: 50rem;
}
@media screen and (max-width: 900px) {

  form.quote-form.glassmorph.p-10.min-w-96.rounded-xl.shadow-lg.w-9\/12 {
    width: 100%;
  }
  /* .shadow-2xl {
    --tw-shadow: 0 25px 50px -12px rgba(13, 139, 139, 0.25) !important;
    box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000),
      var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow) !important;
  } */
}
</style>
