<template>
  <div class="full-section" :style="{'backgroundImage': 'url(' + bkgd_img + ')'}">

		<div class="flex justify-center pt-10 pb-5 pr-4 mt-12">
			<img src="@/assets/logo.svg" class="w-14"/>
			<div class="pl-2 text-left">
				<p class="text-2xl font-bold text-white">BARK BADGE</p>
					<span class="text-xl font-bold block text-theme">Wholesale <span class="strokeme">Center</span></span>
			</div>
		</div>

    <form class="login-area">
      <router-view />
    </form>

  </div>
</template>
<script>
// import axios from 'axios'

export default {
  name: "WaitForm",
  components: {},
  data() {
    return {
      processing: false,
      successAlert: false,
      reminder: false,
      feedback: '',
      form_fields: [
        {label: 'Company Name', placeholder:'Acme Co.', value:'', input_type:'text', isRequired:false},
        {label: 'Company Phone Number', placeholder:'+1 (770) 555-5555', value:'', input_type:'text', isRequired:false},
        {label: 'Company Email', placeholder:'my@email.com', value:'', input_type:'text', isRequired:false},
        {label: 'Country', placeholder:'Wakanda', value:'', input_type:'text', isRequired:false},

      ],
      rep_fields: [
        {label: 'Represenative Name', placeholder:'John Doe', value:'', input_type:'text', isRequired:false},
      ],
      social_fields:[
        {label: 'Facebook', placeholder:'facebook.com/', value:'', input_type:'text', isRequired:false},
        {label: 'Twitter', placeholder:'twitter.com or @twitter', value:'', input_type:'text', isRequired:false},
        {label: 'Instagram', placeholder:'instagram.com or @instagram', value:'', input_type:'text', isRequired:false},
        {label: 'LinkedIn', placeholder:'linkedin.com/', value:'', input_type:'text', isRequired:false},
      ],
      bkgd_img: 'https://images.unsplash.com/photo-1504194782011-e78a3bf9d300?ixlib=rb-1.2.1&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=870&q=80',
    };
  },
  methods: {
    validate(){
      if(this.form_fields[0].value == '' ||
          this.form_fields[1].value == '' ||
          this.form_fields[2].value == '' ||
          this.form_fields[3].value == '' ||
          this.form_fields[4].value == '' ||
          this.form_fields[5].value == '' ){
        this.feedback = 'Please enter all fields in this section'
        this.reminder = true
      }else{
        // this.sendWaitlistForm()
      }
    },
    // sendWaitlistForm() {
    //   this.processing = true
    //   const config ={
    //     headers: {}
    //   }
    //   const payload = {
    //     company_name: this.form_fields[0].value,
    //     mc_number: this.form_fields[2].value,
    //     dot_number: this.form_fields[1].value,
    //     company_website: this.form_fields[3].value,
    //     company_phone: this.form_fields[4].value,
    //     company_email: this.form_fields[5].value,
    //     rep_name: this.rep_fields[0].value,
    //     rep_email: this.rep_fields[1].value,
    //     rep_phone: this.rep_fields[2].value,
    //     facebook: this.social_fields[0].value,
    //     twitter: this.social_fields[1].value,
    //     linkedin: this.social_fields[2].value,
    //     instagram: this.social_fields[3].value
    //   }
    //   axios.post('https://muftardigital.pythonanywhere.com/waitlist', payload, config
    //   ).then((res)=>{
    //     this.processing = false
    //     console.log(res.data)
    //     this.successAlert = true
    //   }).catch((err)=>{
    //     console.log(err)
    //   })
    // },
  },
	created(){
		// this.$router.push({name: 'Register'}) 
	}
};
</script>
<style scroped>
.full-section {
  background-color: #fff;
  width: 100%;
  min-height: 100vh;
  padding-bottom: 5rem;
  overflow: hidden;
  position: relative;
  /* background-attachment: fixed; */
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
}
.login-area{
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%,-50%);
  z-index: 50;
}
.strokeme {
  color: #fff;
	letter-spacing: 1px;
  text-shadow: -.8px -.8px 0 #F9C562, .8px -.8px 0 #F9C562, -.8px .8px 0 #F9C562, .8px .8px 0 #F9C562;
}
</style>
