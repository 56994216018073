import { createStore } from 'vuex'

let cart_list = window.localStorage.getItem('cart');

export default createStore({
  state: {
    cart_list: cart_list ? JSON.parse(cart_list) : [],
    userID: localStorage.getItem('uid') || '',//'Hq1CVq4SnSfkESe01vu1',
    token: localStorage.getItem('token') || '',
    wholesale_prices: [
      {min_count: 30, max_count: 99, price: 1500}
    ]
  },
  mutations: {
    SET_USER_ID(state, userID) {
      state.userID = userID
      window.localStorage.setItem('uid', userID)
      state.auth = true;
    },
    SET_TOKEN(state, token) {
      state.token = token
      window.localStorage.setItem('token', token)
      state.auth = true;
    },
    LOGOUT(state) {
      state.userID = ''
      window.localStorage.removeItem('token')
      window.localStorage.removeItem('uid')
      window.localStorage.removeItem('tag_id')
      window.localStorage.removeItem('pet_page')
      window.localStorage.removeItem('theme')
      window.localStorage.removeItem('new_connection')
      window.localStorage.removeItem('active_flyer')
    },
  },
  actions: {
  },
  modules: {
  }
})
