<template>
<nav id="Sidebar" class="rounded-md w-80 flex-col justify-between border-blue-100 border-r-2 ">
	<div class="h-5/6">
		<router-link :to="{name:'Home'}" class="flex  justify-center pt-10 pb-5 pr-4">
			<img src="@/assets/logo.svg" class="w-14"/>
			<div class="pl-2">
				<p class="text-2xl font-bold text-black">BARK BADGE</p>
					<span class="text-xl font-bold block text-theme">Wholesale <span class="strokeme">Center</span></span>
			</div>
		</router-link>

		<div class="flex items-center justify-center">
			<a href="/order" class="w-1/2 px-3 relative bg-green-400 text-white rounded mx-3 py-2">
				Order Now
			</a>
		</div>

		<a class="bg-white flex items-center justify-center border border-2 space-x-2 w-11/12 my-2 mx-auto rounded-lg p-3 hover:text-indigo-600 cursor-pointer flex" href="/account">
			<img :src="userdata.photo" class="w-16 h-16 rounded-full"/>
			<div class="ml-2">
				<p class="text-theme">{{userdata.businessname}}</p>
				<!-- <p>{{userdata.represenative['fname']}} {{userdata.represenative['lname']}}</p> -->
			</div>
		</a>

		<div class="px-5">
			<ul class="space-y-8 pt-10">
				<li class="flex flex-col space-x-4 items-end cursor-pointer"
						v-for="(mu, index) in menu" :key="index">
					<a :href="mu.link"
						class="border border-2 w-full p-1 pl-4 rounded-lg hover:bg-blue-100"
						:class="{'border-4 border-blue-700 text-blue-700 bg-blue-100': route.name == mu.route}">
						{{mu.label}}</a>
				</li>
			</ul>
		</div>
	</div>

	<div class="bg-white flex items-center justify-center border border-2 space-x-2 w-11/12 my-2 mx-auto rounded-lg p-3 hover:text-indigo-600 cursor-pointer flex">
		<img src="@/assets/images/sales-rep.jpg" class="w-16 h-16 rounded-full"/>
		<div class="ml-2">
			<p class="text-theme">Alexander Dijkema</p>
			<p>Sales Rep</p>
		</div>
	</div>

	<button class="bg-white flex items-center justify-center border border-2 space-x-2 w-8/12 mx-auto rounded-lg p-3 hover:text-indigo-600 cursor-pointer" @click="logout()">
		<svg xmlns="http://www.w3.org/2000/svg" class="h-6 w-6" fill="none" viewBox="0 0 24 24" stroke="currentColor">
			<path stroke-linecap="round" stroke-linejoin="round" stroke-width="2"
				d="M17 16l4-4m0 0l-4-4m4 4H7m6 4v1a3 3 0 01-3 3H6a3 3 0 01-3-3V7a3 3 0 013-3h4a3 3 0 013 3v1" />
		</svg>
		Logout
	</button>


</nav>
</template>
<script setup>
	import { ref, computed } from 'vue'
	import axios from 'axios'
	import { useStore } from 'vuex'
	import { useRoute } from 'vue-router'
	import profile from '@/assets/json/profile.json'
  import menu from '@/assets/json/menu.json'
	// import {mapMutations} from 'vuex'

	const userdata = ref(profile)
	const route = useRoute()
	const store = useStore()

	const logout_user = () => store.commit('LOGOUT')

	let userID = computed( () => {
		return store.state.userID
	});

	// const order_id = route.params.order_id

	console.log(route.name)
	// const gotoRoute = (point) => {
	// 	window.open(`/v1${point}`, '_self')
	// 	console.log(point)
	// }
  // const menu = menu
	const getUserdata = () => {
		const config = {
			headers: {
				userID: userID.value
			}
		}
		axios.get('https://petsproject.skylineexecutive.com/wholesale/one', config
		).then((res)=>{
			userdata.value = res.data.result
			console.log(userdata.value)
		}).catch((err)=>{
			console.log(err)
		})
	}
	const logout = () =>{
		logout_user()
		window.open('/', '_self')
		console.log('Logout')
	}
getUserdata()
</script>
<style>
#Sidebar{
	position: fixed;
	min-height: 100vh;
	width: 20rem;
	top: 0;
	bottom:0;
	left: 0;
	background-color: #fff;
	z-index: 50;
}
.strokeme {
  color: #fff;
	letter-spacing: 1px;
  text-shadow: -.8px -.8px 0 #F9C562, .8px -.8px 0 #F9C562, -.8px .8px 0 #F9C562, .8px .8px 0 #F9C562;
}
</style>
