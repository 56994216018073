<template>
  <div id="app">
    <component :is="layout">
      <router-view/>
    </component>
  </div>
</template>

<script>
  // import { computed } from 'vue'
  // import Toolbar from '@/components/Toolbar.vue'
  const noLayout = 'no-layout'
  import NoLayout from '@/components/layouts/NoLayout.vue'
  import DefaultLayout from '@/components/layouts/DefaultLayout.vue'

  export default {
 components:{
   DefaultLayout,
   NoLayout
 },
  computed: {
    layout () {
      console.log(this.$route.meta.layout)
      return (this.$route.meta.layout || noLayout)
    }
  },
}
</script>

<style>
*{
  margin: 0;
  padding: 0;
}
#app{
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
  min-height: 100%;
  width: 100%;
}
.text-theme{
  color: #F9C562;
}
.bg-theme{
  background-color: #F9C562;
}
:root{
  --primary: #F9C562;
}
</style>
